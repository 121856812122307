import { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import amnesia from "../../assets/img/photoHeader.jpeg";
import { colors } from "../../colors";
import TwoActus from "../../components/Actus/twoActu/TwoActu";
import AgendaSlider from "../../components/AgendaSlider/AgendaSlider";
import BandeauSvg from "../../components/BgImgTop/BandeauSvg";
import BgImgTop from "../../components/BgImgTop/BgImgTop";
import CardSpectacleSlider from "../../components/CardSpectacleSlider/CardSpeactaclesSlider";
import Contact from "../../components/Contact/Contact";
import Titles from "../../components/Titles/Titles";
import ToggleText from "../../components/toggleText/ToggleText";
import { SpectacleContext } from "../../Context/SpectacleContext";
import "./home.css";

const Home = () => {
  const { allSpectacles, isLoading, allActus, allEvents } =
    useContext(SpectacleContext);
  const { hash } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      return;
    } else {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);

      if (element) {
        setTimeout(() => {
          element.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "end",
          });
        }, 1000);
      }
    }
  }, [hash]); // do this on route change

  return (
    <>
      <div className='containerImgPrincipal '>
        <BgImgTop
          bandeau
          image={amnesia}
          title='BIENVENUE À LA BOITAZIK !'
          subtitle='Créatrice de spectacles musicaux'
        />
      </div>
      {/* TOGGLE PRESENTATION */}
      <Container className='relative' fluid>
        <Container className='containerPadding100 mb-20'>
          <div className='toggleTextHome relative'>
            <ToggleText />
          </div>
        </Container>
      </Container>
      {!isLoading && (
        <>
          {allEvents && allEvents.length !== 0 && (
            <>
              <div
                className='relative'
                style={{
                  borderBottom: `3px solid ${colors.violetClair} `,
                }}>
                <div className='bandeauAbsolute'>
                  <BandeauSvg color={"white"} />
                </div>
                <BandeauSvg color={colors.violetClair} />
              </div>
              {/* AGENDA */}
              <Container
                fluid
                className='containerWhite containerPadding100 relative'>
                <Container className='mb-100'>
                  <Row>
                    <div>
                      <div className='btnTitle'>
                        <Titles
                          title={"AGENDA"}
                          color={"var(--bg-violet-fonce)"}
                          margin='0 0 0px 0'
                        />
                        <div className=' btn-custom rose noneResponsive'>
                          <Link to='/agenda'>TOUTES LES DATES</Link>
                        </div>
                      </div>
                      <AgendaSlider data={allEvents} />
                      <div className=' btnAgenda btn-custom violet dflexResponsive'>
                        <Link to='/agenda'>TOUTES LES DATES</Link>
                      </div>
                    </div>
                  </Row>
                </Container>
              </Container>
            </>
          )}
          {/* LES SPECTACLES */}
          {allSpectacles && allSpectacles.length !== 0 && (
            <>
              <div className='translateBandeau'>
                <div className='bandeauAbsolute'>
                  <BandeauSvg color={colors.violetFonce} />
                </div>
                <BandeauSvg color={colors.violetClair} />
              </div>
              <Container className='containerPadding50'>
                <div className='btnTitle'>
                  <Titles
                    title={"SPECTACLES"}
                    color={"white"}
                    margin='0 0 60px 0'
                  />
                </div>
                <div className='containerSpectaclesHome'>
                  <div className='ContainerTextSpectacleHome'>
                    <p className='descriptionSpectacleHome'>
                      La Boitazik est équipée d’un studio d’enregistrement et
                      d’une salle de répétition. Elle a été soutenue par la
                      SPEDIDAM, le FCL, la Copie privée, la SACEM, la Drac
                      Nouvelle Aquitaine, la ville de Saint-Maur, le Département
                      Charente Maritime.
                    </p>
                    <div className='btnActuHome btn-custom gris noneResponsive'>
                      <Link to='/spectacles'>TOUS LES SPECTACLES</Link>
                    </div>
                  </div>
                  <CardSpectacleSlider
                    data={allSpectacles}
                    small={false}
                    nbSlide={2}
                    home={true}
                  />
                </div>

                <div className=' btn-custom gris dflexResponsive'>
                  <Link to='/spectacles'>TOUS LES SPECTACLES</Link>
                </div>
              </Container>
              {/* </div> */}
            </>
          )}
          {/* LES ACTUALITES */}
          {allActus && allActus.length !== 0 && (
            <>
              <div className='relative borderBottomWhite'>
                <div className='bandeauAbsolute'>
                  <BandeauSvg color={colors.violetClair} />
                </div>
                <BandeauSvg color={"white"} />
              </div>
              <Container fluid className='containerSliderActuHome relative'>
                <Container className='containerPadding100 mb-50 '>
                  <Row>
                    <div className='btnTitle'>
                      <Titles title={"ACTUALITÉS"} color={"white"} margin='0' />
                      <div className=' btn-custom gris noneResponsive'>
                        <Link to='/actualites'>TOUTES LES ACTUALITÉS</Link>
                      </div>
                    </div>
                    <div className='sliderActuHome '>
                      <TwoActus data={allActus.slice(0, 4)} />
                    </div>
                    <div className=' btn-custom gris dflexResponsive'>
                      <Link to='/agenda'>TOUTES LES ACTUALITÉS</Link>
                    </div>
                  </Row>
                </Container>
              </Container>
            </>
          )}
        </>
      )}
      {/* PARTENAIRES */}
      <Container fluid className='containerWhite relative '>
        <div
          className='bandeau1Partenaire '
          style={{ borderBottom: `4px solid ${colors.violetFonce} ` }}>
          <BandeauSvg color={colors.violetFonce} />
        </div>
        <div className='bandeau1Partenaire second'>
          <BandeauSvg color={"white"} />
        </div>
        <Container className='containerPadding100  mb-70'>
          <Row>
            <div className='textReferenceHome'>
              <Titles
                title={"PARTENAIRES"}
                color={"var(--bg-violet-fonce)"}
                margin='0 0 60px 0'
              />
              <p className='txtRef'>
                Festival d’Avignon, Studio des Champs-Elysées, Théâtre Trévise,
                vingtième Théâtre, Théâtre Clavel, CE EDF-GDF, CE Air France,
                Ville de Paris, A.N.P.E, Ville de Saint-Maur (Grand Théâtre),
                Ville de Joinville (salle Jacques Prévert), Ville de Mulhouse
                (parc des expositions, 3000 personnes), CE Rotschild (salle du
                Club-Med Bercy), Ville de Compiègne, Théâtre de Chennevières,
                Grenier de Bougival, Forum de Falaise, Théâtre de Saint-Malo,
                Ville de Saint-Mandé, Conseils généraux de plusieurs
                départements, Beauséjour et sortie de plage (Châtelaillon) ,
                Salicorne (Saujon) Drôle de Festival (Aytré), ville de Fouras,
                ville de Bandol, Comédie La Rochelle, Alliance Française du
                Congo …
              </p>
              <span>(+ de 600 représentations tous spectacles confondus)</span>
            </div>
          </Row>
        </Container>
        <div
          className='secondBandeau'
          style={{ borderBottom: `4px solid ${colors.violetClair}` }}>
          <BandeauSvg color={colors.violetClair} />
        </div>
        <div className='secondBandeau' style={{ bottom: 0 }}>
          <BandeauSvg color={colors.violetFonce} />
        </div>
      </Container>
      {/* CONTACT */}
      <Container fluid>
        <Container>
          <Row className='containerPadding100'>
            <Col className='colContact'>
              <div className='containerFormulaireHome'>
                <Titles title={"Contact"} color={"white"} margin='0 0 60px 0' />
                <div className='containerAdresseHome'>
                  <p>7 B avenue de l'abbé Guichard</p>
                  <p>17340 Chatelaillon-Plage</p>
                  <br />
                  <p>TEL: 06 11 83 00 49</p>
                  <p>N° SIRET: 452 075 088 00040 Code APE : 9001 Z</p>
                </div>
                <Contact />
              </div>
            </Col>
            <Col className='map'>
              <div className='mapouter'>
                <div className='gmap_canvas'>
                  <iframe
                    title='map'
                    width='500'
                    height='460'
                    id='gmap_canvas'
                    src={`https://maps.google.com/maps?q=7%20bis%20rue%20de%20l'abbes%20guichard%2017340%20chatelaillon-plage&t=&z=15&ie=UTF8&iwloc=&output=embed`}></iframe>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Home;
