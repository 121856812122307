// import './agenda.css'
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import IsLoading from "../../components/IsLoading/IsLoading";
import ItemAgenda from "../../components/ItemAgenda/ItemAgenda";
import { getOneEvent } from "../../request/agenda";
import "./agendaDetails.css";

const AgendaDetails = () => {
  const { id } = useParams();
  const [event, setEvent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const getEvent = async () => {
    const event = await getOneEvent(id);
    setEvent([event]);
    console.log(event);
  };

  useEffect(() => {
    getEvent().then(() => {
      setIsLoading(false);
    });
  }, [id]);

  return isLoading ? (
    <IsLoading />
  ) : (
    <div className='container agendaDetailContainer'>
      {event && event.length !== 0 && (
        <div className='agendaContainer '>
          <div className='titleAgenda'>Agenda</div>
          {event.map((event, index) => {
            return <ItemAgenda key={index} event={event} />;
          })}
        </div>
      )}
    </div>
  );
};

export default AgendaDetails;
