import { Link } from "react-router-dom"
import {
	bigNumberDateAgenda,
	extractHourFromDate,
} from "../bigNumberDateAgenda"
import { ConvertDate } from "../ConvertDate"
import "./itemAgenda.css"

const ItemAgenda = ({ event, noAffiche }) => {
	let adressGoogle = event.adresse

	return (
		<div className="itemAgendaContainer d-flex">
			{console.log("event", event.spectacle.avis)}
			<div className="dateContainer col-sm-2">
				<div>{bigNumberDateAgenda(event.date)}</div>
			</div>
			<div className="lieuContainer  col-sm-9">
				<div className="adresseContainer">
					<Link
						to={`/spectacles/detail/${event.spectacle._id}`}
						className="nomSpectacleAgenda"
					>
						{event.spectacle.nom}
					</Link>

					<div className=" adressContainer">
						<p className="adresse"> {event.adresse}</p>
					</div>
					<div className="dateResponsive">
						{ConvertDate(event.date)} -{" "}
						{extractHourFromDate(event.date)}
					</div>
					<div className="containerVoir">
						{event.spectacle.avis && (
							<div className=" avisContainer">
								<a
									target={"_blank"}
									rel="noreferrer"
									href={event.spectacle.avis}
									className="voirAvis "
								>
									Voir les avis -
								</a>
							</div>
						)}
						<Link
							to={`/spectacles/detail/${event.spectacle._id}`}
							className="voirSpectacle "
						>
							Voir le spectacle
						</Link>
					</div>
				</div>
				<div className="btnAgendaItem">
					<a
						className="btn-custom grisFonce m-r-5"
						href={`https://www.google.fr/maps/place/${adressGoogle}`}
						target="blank"
					>
						ITINÈRAIRE
					</a>
					<a className="btn-custom violet" href={event.billeterie}>
						BILLETERIE
					</a>
				</div>
			</div>
			{!noAffiche && (
				<Link
					to={`/spectacles/detail/${event.spectacle._id}`}
					className="imageItemAgenda d-flex justify-content-end col-sm-1 "
				>
					{event.spectacle.affiche && (
						<img
							src={event.spectacle.affiche.secure_url}
							className="imgItemAgenda"
							alt={`affiche du spectacle ${event.spectacle.nom}`}
						/>
					)}
				</Link>
			)}
		</div>
	)
}

export default ItemAgenda
