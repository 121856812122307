export const extractHourFromDate = (date) => {
  let hourConverted = new Date(date.slice(0, 16)).toLocaleTimeString("fr-FR");
  hourConverted = hourConverted.substring(0, 5);
  return hourConverted;
};

export const bigNumberDateAgenda = (date) => {
  const number = new Date(date).toLocaleDateString("fr-FR", { day: "numeric" });
  const day = new Date(date).toLocaleDateString("fr-FR", { weekday: "long" });
  const month = new Date(date).toLocaleDateString("fr-FR", { month: "long" });
  const year = new Date(date).toLocaleDateString("fr-FR", { year: "numeric" });
  const hour = extractHourFromDate(date);

  return (
    <div>
      <div className='subtitle'>{day}</div>
      <div className='date'>{number}</div>
      <div className='subtitle'>
        {month} {year}
      </div>
      <div className='subtitle'>{hour}</div>
    </div>
  );
};
