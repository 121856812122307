// ***********************************************//
import { useNavigate } from "react-router-dom";
import { A11y, Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import { extractHourFromDate } from "../bigNumberDateAgenda";
import { ConvertDate } from "../ConvertDate";
import "./agendaSlider.css";

const AgendaSlider = ({ data }) => {
  const history = useNavigate();
  const linkToAgenda = (id) => {
    history(`agenda/detail/${id}`);
  };
  return (
    <Swiper
      className='containerAgendaSliderHome'
      // install Swiper modules
      modules={[Navigation, A11y, Autoplay]}
      spaceBetween={20}
      slidesPerView={data.length >= 1 ? 3 : 1}
      navigation
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        800: {
          slidesPerView: 2,
        },
        1400: {
          slidesPerView: 3,
        },
      }}
      // autoplay={{ delay: 5000 }}
      loop={false}>
      {data.map((data, index) => {
        return (
          <SwiperSlide key={index}>
            <div
              onClick={() => linkToAgenda(data._id)}
              key={index}
              className='cardAgenda'>
              <div className='infoEvent'>
                <div className='titleEvent'>
                  {data.titre || data.spectacle.nom}
                </div>
                <p className='dateEvent'>
                  {ConvertDate(data.date, "sansJour")}
                </p>
              </div>
              <div className='bottomInfo'>
                {data.minDescription && (
                  <p className='adresseEvent'>{data.minDescription}</p>
                )}
                {data.adresse && <p className='adresseEvent'>{data.adresse}</p>}
                <p className='heure'>{extractHourFromDate(data.date)}</p>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default AgendaSlider;
