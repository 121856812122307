import { useNavigate } from "react-router-dom";
import "./cardcustomXs.css";
const CardCustomXs = ({ spectacle, url, click }) => {
  const history = useNavigate();
  const linkToAgenda = (id) => {
    history(`/spectacles/detail/${spectacle._id}`);
  };
  return (
    <a href="#top">
      <div
        onClick={linkToAgenda}
        className="cardXs text-white card-has-bg click-col"
        style={{
          backgroundImage: `url(${spectacle.affiche.secure_url})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="card-img-overlay d-flex flex-column">
          <div className="card-body">
            <h4 className="card-title titleCardCustom mt-0 ">
              {spectacle.nom}
            </h4>
            <small>
              <p>{spectacle.minDescription}</p>
            </small>
          </div>
        </div>
      </div>
    </a>
  );
};

export default CardCustomXs;
