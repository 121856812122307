import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../assets/img/logo_boitazik.png";
import useWindowDimensions from "../../Context/getWindow";
import "./navbar.css";
const NavBar = () => {
  const { width } = useWindowDimensions();
  const [handleMenu, setHandleMenu] = useState(false);
  const styleWhenTrue = {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    flex: "1",
  };

  const styleWhenFalse = {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row",
    flex: "1",
  };

  const closeNav = () => {
    if (handleMenu) {
      setHandleMenu(false);
    }
  };

  useEffect(() => {
    // Fermture du menu si on est au dessus de 1200px
    if (width >= 1200) {
      setHandleMenu(false);
    }
  }, [width]);

  return (
    <div className='navContainer'>
      <nav className='headerNav' style={{ position: "relative" }}>
        <div className='nav container'>
          <div className='logo-container'>
            <Link to='/'>
              <img className='logo' src={Logo} alt='logo de la boite a zik' />
            </Link>
            <FontAwesomeIcon
              onClick={() => setHandleMenu(!handleMenu)}
              style={{ width: "auto", height: "1.5em", color: "white" }}
              className='font-bars'
              icon='bars'
            />
          </div>
          <div
            className='link-container'
            style={handleMenu ? styleWhenTrue : styleWhenFalse}>
            <NavLink
              className={(active) =>
                !handleMenu && active.isActive
                  ? " linkRow actNav"
                  : handleMenu && active.isActive
                  ? "linkColumn actNav"
                  : handleMenu && !active.isActive
                  ? "linkColumn  "
                  : "linkRow "
              }
              to='/'
              onClick={closeNav}>
              Accueil
            </NavLink>
            <NavLink
              className={(active) =>
                !handleMenu && active.isActive
                  ? " linkRow actNav"
                  : handleMenu && active.isActive
                  ? "linkColumn actNav"
                  : handleMenu && !active.isActive
                  ? "linkColumn  "
                  : "linkRow "
              }
              to='/actualites'
              onClick={closeNav}>
              Actualités
            </NavLink>
            <NavLink
              className={(active) =>
                !handleMenu && active.isActive
                  ? " linkRow actNav"
                  : handleMenu && active.isActive
                  ? "linkColumn actNav"
                  : handleMenu && !active.isActive
                  ? "linkColumn  "
                  : "linkRow "
              }
              to='/agenda'
              onClick={closeNav}>
              Agenda
            </NavLink>
            <NavLink
              className={(active) =>
                !handleMenu && active.isActive
                  ? " linkRow actNav"
                  : handleMenu && active.isActive
                  ? "linkColumn actNav"
                  : handleMenu && !active.isActive
                  ? "linkColumn  "
                  : "linkRow "
              }
              to='/spectacles'
              onClick={closeNav}>
              Spectacles
            </NavLink>
            {/* <NavLink
              className={(active) =>
                !handleMenu && active.isActive
                  ? " linkRow actNav"
                  : handleMenu
                  ? "linkColumn"
                  : "linkRow"
              }
              to='/boxSongs'
              onClick={closeNav}>
              BoxSongs
            </NavLink> */}
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='http://www.boxsongs.com/index.htm'
              className={!handleMenu ? " linkRow  " : "linkColumn"}>
              BoxSongs
            </a>
            <NavLink
              className={(active) =>
                !handleMenu && active.isActive
                  ? " linkRow "
                  : handleMenu
                  ? "linkColumn"
                  : "linkRow"
              }
              to={{ pathname: "/", hash: "#contact" }}
              onClick={closeNav}>
              Contact
            </NavLink>
          </div>
        </div>
      </nav>
      {/* <div
        style={{
          position: "absolute",
          top: "20px",
          width: "115%",
          left: 0,
          zIndex: -1,
          transform: "rotateY(180deg) rotateX(180deg) rotateZ(2deg)",
        }}
      >
        <BandeauSvg color={colors.violetFonce} />
      </div>
      <div
        style={{
          position: "absolute",
          top: "19px",
          width: "115%",
          left: 0,
          zIndex: -1,
          transform: "rotateY(180deg) rotateX(180deg) rotateZ(2deg)",
        }}
      >
        <BandeauSvg color={"black"} />
      </div> */}
    </div>
  );
};

export default NavBar;
