import { useContext } from "react";
import { Container } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import { colors } from "../../colors";
import CardCustom from "../../components/CardCustom/CardCustom";
import Titles from "../../components/Titles/Titles";
import { SpectacleContext } from "../../Context/SpectacleContext";
import "./spectacles.css";

const Spectacles = () => {
  const { allSpectacles, isLoading } = useContext(SpectacleContext);
  return (
    <Container>
      {isLoading ? (
        <div className='loading'>
          <TailSpin
            heigth='100'
            width='100'
            color={colors.violetClair}
            ariaLabel='loading'
          />
        </div>
      ) : (
        <div className='containerSpectacles'>
          <Titles title={"SPECTACLES"} color={"white"} />

          <div className='spectacles'>
            {allSpectacles.map((spectacle, index) => (
              <CardCustom
                key={index}
                index={index}
                spectacle={spectacle}
                url={`detail/${spectacle._id}`}
                bg={"var(--bg-violet-clair)"}
              />
            ))}
          </div>
        </div>
      )}
    </Container>
  );
};

export default Spectacles;
