export const ConvertDate = (date, type, year, month, day) => {
  // Paramètre pour convertir la date au format français souhaité
  const options =
    type === "long"
      ? {
          weekday: "short",
          year: "numeric",
          month: "long",
          day: "numeric",
        }
      : type === "sansJour"
      ? {
          year: "numeric",
          month: "long",
          day: "numeric",
        }
      : {
          year: "numeric",
          month: "long",
          day: "numeric",
        };
  const dateConvert = new Date(date).toLocaleDateString("fr-FR", options);
  return dateConvert;
};
