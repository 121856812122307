import { Link } from "react-router-dom";
import { ConvertDate } from "../../ConvertDate";
// import img from "../../assets/img/imgBg.JPG";
import "../actus.css";

const TwoActus = ({ data }) => {
  return data.map((actu, index) => {
    return (
      <div key={index} className='containerActus col-lg-6'>
        <div className='imgActus'></div>
        <div className='containerTextActus'>
          <h3>{actu.nom}</h3>
          <div className='dateActu'>{ConvertDate(actu.date)}</div>
          <span>{actu.minDescription}</span>
          <p>{actu.description.substr(0, 250) + "..."}</p>
          <Link
            to={`/actualites/detail/${actu._id}`}
            className='btn-custom rose'>
            EN SAVOIR PLUS
          </Link>
        </div>
      </div>
    );
  });
};

export default TwoActus;
