import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "../../colors";
import BandeauSvg from "./BandeauSvg";
import "./bgImgTop.css";

const BgImgTop = ({ image, linkBtn, title, subtitle, bandeau, avis }) => {
  return (
    <div
      id='intro'
      style={{
        height: "100vh",
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "top",
      }}
      className='bg-image shadow-2-strong relative'>
      <div
        className='mask'
        style={{ backgroundColor: "rgba(0, 0, 0, 0.4)", height: "100%" }}>
        <div
          style={{
            height: "100%",
            paddingTop: "100px",
          }}
          className='container d-flex align-items-center justify-content-center text-center h-100'>
          <div className='text-white'>
            <h1 className='mb-3 titleBgImgTop'>{title}</h1>
            {subtitle && <h5 className='mb-4 subtitleBgImgTop'>{subtitle}</h5>}
            <div className='d-flex justify-content-center align-items-center'>
              {linkBtn && (
                // <div className='btnBoxSongs m-auto'>
                <a
                  target={"_blank"}
                  rel='noreferrer'
                  href={`${linkBtn}`}
                  className='btn-custom rose m-r-5 '>
                  ACHETER L'ALBUM
                </a>
                // </div>
              )}
              {avis && (
                // <div className='btnBoxSongs m-auto'>
                <a
                  target={"_blank"}
                  rel='noreferrer'
                  href={`${avis}`}
                  className='btn-custom violet '>
                  VOIR LES AVIS
                </a>
                // </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {bandeau && (
        <>
          <div
            className='bandeauBgImg '
            style={{ borderBottom: `4px solid white ` }}>
            <BandeauSvg color={"white"} />
          </div>
          <div className='bandeauBgImg secondBgImg'>
            <BandeauSvg color={colors.violetFonce} />
          </div>
        </>
      )}
    </div>
  );
};

export default BgImgTop;
