import Titles from "../../components/Titles/Titles";

const MentionsLegales = () => {
  return (
    <div className='container containerActu'>
      <Titles title={"Mentions légales"} color={"white"} />
      <div className='blocActuality'>
        <p>
          Le présent site a été réalisé réalisé par <span>l’agence AJDEV </span>
          , et est la propriété de L’association La BoitAZik, dont le siège
          social est situé à l’adresse suivante :
        </p>
        <ul>
          <li>7 B l'Abbé Guichard, 17340 Châtelaillon-Plage</li>
          <li>Tél : 06 11 83 00 49</li>
          <li>Numéro de SIRET : 452 075 088 00040 Code APE :9001 Z</li>
        </ul>
        <br />
        <span className='bold'>Directeurs de publication</span>
        <ul>
          <li>
            La Directrice de la publication du site web est Mademoiselle
            Delavoie Amandine
          </li>
          <li>Hébergement du site Internet : OVH</li>
        </ul>
        <br />
        <span className='bold'>Design / Conception</span>
        <ul>
          <li>AJDEV</li>
          <li>14, rue du Général De Gaulle 17290 Thairé</li>
          <li> 07 50 98 72 96 / 06 63 26 38 01</li>

          <li className='bold'>
            <a href='mailto:amandinedelavoie@gmail.com'>
              amandinedelavoie@gmail.com
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MentionsLegales;
