import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { styled } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import ReactPlayer from "react-player";
// TESTER CE CODE pour le carousel de video
// https://codepen.io/Woblin/pen/ZKWEbW
// Site noir et violet
// https://dribbble.com/shots/17525835-Landing-Page-design-for-Music-Festival
import { useParams } from "react-router-dom";
import { colors } from "../../colors";
import BgImgTop from "../../components/BgImgTop/BgImgTop";
import CardSpectacleSlider from "../../components/CardSpectacleSlider/CardSpeactaclesSlider";
import IsLoading from "../../components/IsLoading/IsLoading";
import ItemAgenda from "../../components/ItemAgenda/ItemAgenda";
// import Button from "../../components/Button/Button";
import Titles from "../../components/Titles/Titles";
import { SpectacleContext } from "../../Context/SpectacleContext";
import { getDateOfSpectacle } from "../../request/agenda";
import { getOneSpectacle } from "../../request/spectacle";
import "./spectacleDetails.css";

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  background-color: #e5e6e8;
  width: 100%;

  padding: 12px 16px;
  border: none;
  display: flex;
  justify-content: center;
  color:${colors.violetFonce};
  margin: 0 5px;
  border-radius:10px;


  &:hover {
    background-color: rgb(96, 105, 146, 0.5);
    border-radius:10px;
    color:white;
  }

  &:focus {
    color: #fff;
    border-radius: 10px;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: ${colors.violetClair};
    color: white;
    border-radius: 10px;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
    color:${colors.violetFonce}
    background-color: #e5e6e8;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  background-color: #f5f5f5;
  padding: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  white-space: pre-line;

  line-height: 20px;
  color: ${colors.violetFonce};
`;

const TabsList = styled(TabsListUnstyled)`
  min-width: 320px;
  background-color: #e5e6e8;
  padding: 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: ${colors.violetFonce};
`;

const SpectacleDetails = () => {
  const { allSpectacles } = useContext(SpectacleContext);
  const { id } = useParams();
  // const day = new Date().getFullYear();
  const [isLoading, setIsLoading] = useState(true);
  const [currentMusic, setCurrentMusic] = useState(0);
  const [dateOfSpectacle, setDateOfSpectacle] = useState([]);

  const [handlePlaylist, setHandlePlaylist] = useState(false);
  const [spectacleInfos, setSpectacleInfos] = useState([]);

  const nextMusic = () => {
    if (spectacleInfos.musique.length > 0) {
      // Si on est arrivé à la dernière musique, on ne peut pas écouter la prochaine.
      // Alors on déclenche le stop de la musique ?
      // On grise le bouton suivant
      if (currentMusic < spectacleInfos.musique.length - 1) {
        setCurrentMusic((oldCurrentMusic) => oldCurrentMusic + 1);
      }
    }
  };

  const previousMusic = () => {
    if (spectacleInfos.musique.length > 0) {
      // Si on est arrivé à la dernière musique, on ne peut pas écouter la prochaine.
      // Alors on déclenche le stop de la musique ?
      // On grise le bouton suivant
      if (currentMusic >= spectacleInfos.musique.length - 1) {
        setCurrentMusic((oldCurrentMusic) => oldCurrentMusic - 1);
      }
    }
  };
  const openPlaylist = () => {
    setHandlePlaylist(!handlePlaylist);
  };

  const getInfoSpectacle = async () => {
    const response = await getOneSpectacle(id);
    if (response && response.length !== 0) {
      setSpectacleInfos({
        nom: response.nom,
        minDescription: response.minDescription,
        description: response.histoire,
        video: response.video ? response.video : "",
        musique: response.musique ? response.musique : [],
        affiche: response.affiche,
        imgXL: response.imgXL,
        date: response.date,
        mise_en_scene: response.mise_en_scene,
        note_des_auteurs: response.note_des_auteurs,
        boxSong: response.achat,
        avis: response.avis,
      });
    }
  };

  useEffect(() => {
    const getEventsOfSpectacle = async () => {
      const eventOfSpectacle = await getDateOfSpectacle(id);
      setDateOfSpectacle(eventOfSpectacle);
    };

    getInfoSpectacle().then(() => {
      getEventsOfSpectacle().then(() => {
        setIsLoading(false);
      });
    });
  }, [id]);

  return isLoading ? (
    <IsLoading />
  ) : (
    <div id='top' className={spectacleInfos.length !== 0 ? "" : "h-100"}>
      {spectacleInfos && spectacleInfos.length !== 0 && (
        <div>
          {/* BG IMAGE */}
          <BgImgTop
            image={spectacleInfos.imgXL.secure_url}
            title={spectacleInfos.nom}
            subtitle={spectacleInfos.minDescription}
            linkBtn={spectacleInfos.boxSong}
            linkName="Acheter l'album"
            avis={spectacleInfos.avis}
          />
          {/* Bandeau avec lecteur  sous la grosse image à l'horizontal 1er essai*/}
          <div className='containerCustom container relative'>
            {spectacleInfos.musique.length !== 0 && (
              <div className='containerLecteurAudio'>
                <AudioPlayer
                  className='lecteurAudio'
                  src={spectacleInfos.musique[currentMusic].secure_url}
                  showSkipControls
                  showJumpControls={false}
                  showFilledVolume={false}
                  onClickPrevious={previousMusic}
                  onClickNext={nextMusic}
                  customAdditionalControls={[
                    <FontAwesomeIcon
                      icon='list'
                      color={colors.bgGris}
                      onClick={openPlaylist}
                    />,
                  ]}
                  showFilledProgress
                  style={{
                    backgroundColor: colors.black,
                    height: "100%",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                  header={spectacleInfos.musique[currentMusic].name}
                  layout='horizontal-reverse'
                />
              </div>
            )}

            {/* playlist déroulée */}
            {handlePlaylist && (
              <div className='playlist'>
                {spectacleInfos.musique.map((music, index) => {
                  return (
                    <div
                      className='playlistItem'
                      style={{
                        backgroundColor:
                          currentMusic === index
                            ? "rgb(236, 124, 166, 0.4)"
                            : "",
                      }}
                      onClick={() => setCurrentMusic(index)}>
                      <FontAwesomeIcon
                        icon='play-circle'
                        size={"1x"}
                        color={colors.bgGris}
                        style={{ marginRight: "10px" }}
                      />
                      <p>{music.name}</p>
                    </div>
                  );
                })}
              </div>
            )}

            {/* Toggle infos */}
            <div className='toggle-container'>
              <TabsUnstyled defaultValue={0}>
                <TabsList>
                  <Tab>Description</Tab>
                  <Tab>Notes des auteurs</Tab>
                  <Tab>Intention de mise en scène</Tab>
                </TabsList>
                <TabPanel value={0}>{spectacleInfos.description}</TabPanel>
                <TabPanel value={1}>{spectacleInfos.note_des_auteurs}</TabPanel>
                <TabPanel value={2}>{spectacleInfos.mise_en_scene}</TabPanel>
              </TabsUnstyled>
            </div>

            {/* AGENDA */}
            {dateOfSpectacle && dateOfSpectacle.length !== 0 && (
              <div style={{ maxWidth: "800px", margin: "0 auto" }}>
                <div className='titleAgenda'>Agenda</div>
                {/* <Titles title={"AGENDA"} color={"white"} margin='0 0 60px 0' /> */}
                {dateOfSpectacle.map((event, index) => {
                  return <ItemAgenda key={index} event={event} noAffiche />;
                })}
              </div>
            )}
          </div>
          {/* YOUTUBE */}
          {spectacleInfos?.video &&
            spectacleInfos.video.includes("https://www.youtube.com/watch") && (
              <div className='bandeauYoutubeContainer '>
                <div className='row bandeauYoutube  container flex-md-row '>
                  <div className='col-sm-12  col-md-6 col-lg-6 m-auto d-flex videoYoutube'>
                    {/* <VideoCarousel  /> */}
                    <ReactPlayer
                      url={spectacleInfos.video}
                      width='100%'
                      height='280px'
                      pip
                      autoPlay={false}
                      controls
                      playing={false}
                    />
                  </div>
                  <div className='textYoutube col-sm-12 col-lg-6 col-md-6 align-items-start justify-content-center d-flex flex-column'>
                    <Titles title='Réseaux' color='white' margin='0 0 20px 0' />
                    <div className='textReseaux'>
                      Retrouvez-vous nous sur notre chaîne Youtube !
                    </div>
                    <div className='btnYoutube'>
                      <Button
                        variant='custom'
                        onClick={() =>
                          window.open("https://www.youtube.com/user/Laboitazik")
                        }>
                        S'abonner
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          <Container fluid>
            <Row>
              <div className='containerAgendaDansSpectacle'>
                <Container>
                  <Titles
                    title='Les spectacles'
                    color={colors.violetClair}
                    // color="white"
                    margin='0px 0px 40px 0px'
                  />

                  <CardSpectacleSlider data={allSpectacles} small={true} />
                </Container>
              </div>
            </Row>
          </Container>
        </div>
      )}
    </div>
  );
};

export default SpectacleDetails;
