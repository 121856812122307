import { A11y, Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import CardCustom from "../CardCustom/CardCustom";
import CardCustomXs from "../CardCustomXs/CardCustomXs";

const CardSpectacleSlider = ({ data, small, nbSlide, home }) => {
  return (
    <Swiper
      // install Swiper modules
      modules={[Navigation, A11y, Autoplay]}
      spaceBetween={20}
      slidesPerView={small ? 4 : nbSlide}
      navigation
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        800: {
          slidesPerView: 1,
        },
        1000: {
          slidesPerView: nbSlide && nbSlide > 1 ? nbSlide - 1 : 3,
        },
        1400: {
          slidesPerView: small ? 4 : nbSlide,
        },
      }}
      className={home ? "flex2" : "nada"}
      style={{ marginTop: "60px" }}
      // autoplay={{ delay: 5000 }}
    >
      {data.map((spectacle, index) => {
        return (
          <SwiperSlide key={index}>
            {small ? (
              <CardCustomXs
                index={index}
                spectacle={spectacle}
                url={`/spectacles/detail/${spectacle._id}`}
                bg={"var(--bg-violet-clair)"}
              />
            ) : (
              <CardCustom
                index={index}
                spectacle={spectacle}
                url={`spectacles/detail/${spectacle._id}`}
                bg={"var(--bg-violet-clair)"}
              />
            )}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default CardSpectacleSlider;
