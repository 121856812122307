import axios from "axios";
import { API } from "../Context/constant";

export const getAllActus = async () => {
  // Récupération de toutes les actus
  try {
    const response = await axios.get(`${API}actualites`);
    return response.data;
    return [];
  } catch (error) {
    return [];
  }
};

export const getOneActu = async (id) => {
  // Récupération d'une actu
  try {
    const response = await axios.get(`${API}actualite/${id}`);
    return response.data;
  } catch (error) {
    return [];
  }
};
