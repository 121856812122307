import { createContext, useEffect, useState } from "react";
import { getAllActus } from "../request/actus";
import { getAllEvents } from "../request/agenda";
import { getAllSpectacle } from "../request/spectacle";
export const SpectacleContext = createContext();

const SpectacleContextProvider = (props) => {
  const [allSpectacles, setAllSpectacles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [allEvents, setAllEvents] = useState([]);
  const [allActus, setAllActus] = useState([]);

  const getEvents = async () => {
    const allEvents = await getAllEvents();
    setAllEvents(allEvents);
  };
  const getActus = async () => {
    const actus = await getAllActus();
    setAllActus(actus);
  };
  const getSpectacle = async () => {
    const spectacles = await getAllSpectacle();
    setAllSpectacles(spectacles);
  };

  useEffect(() => {
    const fetchData = async () => {
      getSpectacle().then(() => {
        getEvents().then(() => {
          getActus().then(() => {
            setIsLoading(false);
          });
        });
      });
    };
    fetchData();
  }, []);

  return (
    <SpectacleContext.Provider
      value={{ allSpectacles, isLoading, allActus, allEvents }}>
      {props.children}
    </SpectacleContext.Provider>
  );
};

export default SpectacleContextProvider;
