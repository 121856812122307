import { Link } from "react-router-dom";
import "./notfound.css";

const NotFound = () => {
  return (
    <div className='containerNotFound'>
      <h1> Error page 404</h1>
      <p className='zoom-area'>Cette page n'existe pas</p>
      <section className='error-container'>
        <span className='four'>
          <span className='screen-reader-text'>4</span>
        </span>
        <span className='zero'>
          <span className='screen-reader-text'>0</span>
        </span>
        <span className='four'>
          <span className='screen-reader-text'>4</span>
        </span>
      </section>
      <div>
        <Link className='more-link' to='/'>
          Pour revenir à l'accueil
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
