import axios from "axios";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { API } from "../../Context/constant";
import IsLoadingContact from "../IsLoading/IsLoadingContact";
import "./contact.css";

const Contact = () => {
  const [email, setEmail] = useState("");
  const [sujet, setSujet] = useState("");
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleForm = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("email", email);
    formData.append("sujet", sujet);
    formData.append("message", message);
    setIsLoading(true);
    const response = await axios.post(`${API}sendMail`, formData);
    if (response.status === 200) {
      setIsLoading(false);
      setEmail("");
      setSujet("");
      setMessage("");
      setResponse("Mail envoyé");
      setTimeout(() => {
        setResponse("");
      }, 1800);
    } else {
      setIsLoading(false);

      setResponse(
        "Une erreur est survenue lors de l'envoie du mail, vous pouvez adresser votre message à l'adresse suivante : laboitazik17@gmail.com"
      );
      setTimeout(() => {
        setResponse("");
      }, 1800);
    }
  };
  return (
    <form id='contact' onSubmit={(e) => handleForm(e)}>
      <input
        type='email'
        name='email'
        id='email'
        placeholder='Votre email'
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
      <input
        type='text'
        name='nom'
        id='nom'
        value={sujet}
        placeholder='Sujet du message'
        onChange={(e) => {
          setSujet(e.target.value);
        }}
      />
      <textarea
        placeholder='Votre message'
        value={message}
        onChange={(e) => {
          setMessage(e.target.value);
        }}></textarea>
      <Button
        variant='custom'
        className='btn-custom rose btnContact'
        type='submit'>
        {isLoading ? <IsLoadingContact /> : "ENVOYER LE MESSAGE"}
      </Button>
      <div className='responseContact'>{response}</div>
    </form>
  );
};

export default Contact;
