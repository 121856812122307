import { useContext } from "react";
import { Link } from "react-router-dom";
import { ConvertDate } from "../../components/ConvertDate";
import Titles from "../../components/Titles/Titles";
import { SpectacleContext } from "../../Context/SpectacleContext";
import "./actuality.css";

const Actuality = () => {
  const { allActus } = useContext(SpectacleContext);

  const ItemActuality = ({ actu }) => {
    return (
      <div className="itemActuality">
        <article className="postcard dark blue">
          <a
            className="postcard__img_link imgSize"
            href={`/actualites/detail/${actu._id}`}
            style={{
              backgroundImage: `url(${actu.image.secure_url})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top",
              backgroundSize: "cover",
              display: "flex",
            }}
          ></a>
          <div className="postcard__text">
            <h3 className="postcard__title titleViolet">{actu.nom}</h3>
            <div className="postcard__subtitle grey">
              {ConvertDate(actu.date)}
            </div>
            <div className="postcard__bar"></div>
            <div className="postcard__preview-txt ">{actu.minDescription}</div>
            <div className="postcard__preview-txt">
              {actu.description.substr(0, 250) + "..."}
            </div>

            <div className="postcard__tagbox">
              <Link
                to={`/actualites/detail/${actu._id}`}
                className="btn-custom rose"
              >
                EN SAVOIR PLUS
              </Link>
            </div>
          </div>
        </article>
      </div>
    );
  };
  return (
    <div className="container containerActu">
      <Titles title={"Les actualités"} color={"white"} />
      <div className="blocActuality">
        {allActus.map((actu, i) => {
          return <ItemActuality key={i} actu={actu} />;
        })}
      </div>
    </div>
  );
};

export default Actuality;
