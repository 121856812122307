import axios from "axios";
import { API } from "../Context/constant";

export const getAllEvents = async () => {
  // Récupération de toutes les actus
  try {
    const response = await axios.get(`${API}agenda`);
    return response.data;
    return [];
  } catch (error) {
    return [];
  }
};

export const getDateOfSpectacle = async (id) => {
  // Récupération de toutes les actus
  try {
    const response = await axios.get(`${API}agenda/filtre/spectacle/${id}`);
    return response.data;
    return [];
  } catch (error) {
    return [];
  }
};

export const getOneEvent = async (id) => {
  // Récupération d'un événement
  try {
    const response = await axios.get(`${API}agenda/${id}`);
    return response.data;
    return [];
  } catch (error) {
    return [];
  }
};
