import Logo from "../../assets/img/logo_boitazik.png";
import "./footer.css";
import IconFb from "../../assets/icons/facebook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className='containerResponsiveFooter'>
      <div className='footerContainer'>
        <div className='container footerContent '>
          <div className='logoFooter'>
            <img
              className='logoFooterImg'
              src={Logo}
              alt='logo de la boite a zik'
            />
            <a
              target={"_blank"}
              rel='noreferrer'
              href='https://www.legifrance.gouv.fr/loda/id/LEGITEXT000006069570/'>
              <FontAwesomeIcon icon='info-circle' color={"white"} />
              Association loi du 1er Juillet 1901
            </a>
            <Link to='/mentionsLegales'>
              {" "}
              <FontAwesomeIcon icon='info-circle' color={"white"} />
              Mentions légales
            </Link>
          </div>
          <div className='contactFooter'>
            <p className='titreFooter'>Contact</p>
            <a
              target={"_blank"}
              rel='noreferrer'
              href='https://www.google.com/maps?ll=46.057859,-1.086695&z=15&t=m&hl=fr-FR&gl=US&mapclient=embed&q=7+Av.+de+l%27Abb%C3%A9+Guichard+17340+Ch%C3%A2telaillon-Plage'>
              <FontAwesomeIcon icon='map-marker-alt' color={"white"} />7 B
              l'Abbé Guichard, 17340 Châtelaillon-Plage
            </a>
            <p className='phoneFooter'>
              <FontAwesomeIcon icon='phone-alt' color={"white"} />
              06 11 83 00 49
            </p>
          </div>
          <div className='facebookFooter '>
            <p className='titreFooter'>Réseaux</p>

            <a
              target={"_blank"}
              rel='noreferrer'
              href='https://www.facebook.com/la.boitazik'>
              <IconFb />
              Suivez-nous sur notre page Facebook
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
