import "./titles.css";

const Titles = ({ title, color, margin }) => {
  return (
    <div
      className='containerTitles'
      style={{ margin: margin ? margin : "60px 0" }}>
      <p id='p' style={{ color: `${color}`, opacity: "0.2" }}>
        {title}
      </p>
      <h2 id='h2' style={{ color: `${color}` }}>
        {title}
      </h2>
    </div>
  );
};

export default Titles;
