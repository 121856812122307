import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "react-calendar/dist/Calendar.css";
import { Link, useParams } from "react-router-dom";
import { ConvertDate } from "../../components/ConvertDate";
import IsLoading from "../../components/IsLoading/IsLoading";
import { SpectacleContext } from "../../Context/SpectacleContext";
import { getOneActu } from "../../request/actus";
import "./actualityDetails.css";

const ActualityDetails = () => {
  const { allActus } = useContext(SpectacleContext);
  const params = useParams();
  const [actu, setActu] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tabId, setTabId] = useState([]);

  // Récupération de l'actu
  const getActu = async () => {
    const actu = await getOneActu(params.id);
    console.log("acrtuDetail", actu);
    setActu(actu);
  };

  const createTabWithIdActu = (data) => {
    // Création d'un tableau qui contient tous les id d'actus
    if (data.length !== 0) {
      const tabId = [];
      data.forEach((el) => {
        tabId.push(el._id);
      });
      setTabId(tabId);
    }
  };

  const previousActu = () => {
    const indexOfcurrentIdInTabId = tabId.indexOf(params.id);
    // Il faut qu'on trouve dans le tableau d'id, celui de la page actuelle
    // Ensuite on se baladera dans le tableau a partir de la position de l'id trouvé dans le tableau
    const indexPrevious = indexOfcurrentIdInTabId - 1;

    return indexPrevious >= 0 ? (
      <Link
        to={`/actualites/detail/${tabId[indexPrevious]}`}
        className='btn-custom violet'>
        Actualité précédente
      </Link>
    ) : null;
  };

  const nextActu = () => {
    const indexOfcurrentIdInTabId = tabId.indexOf(params.id);
    // Il faut qu'on trouve dans le tableau d'id, celui de la page actuelle
    // Ensuite on se baladera dans le tableau a partir de la position de l'id trouvé dans le tableau
    const indexNext = indexOfcurrentIdInTabId + 1;

    return indexNext < tabId.length ? (
      <Link
        to={`/actualites/detail/${tabId[indexNext]}`}
        className='btn-custom violet'>
        Actualité suivante
      </Link>
    ) : null;
  };

  useEffect(() => {
    createTabWithIdActu(allActus);
    getActu().then(() => {
      setIsLoading(false);
    });
  }, [params.id]);

  return isLoading ? (
    <IsLoading />
  ) : actu.nom !== "" && actu.nom !== undefined ? (
    <Container className='containerActuDetails'>
      <p className='titreActuDetail'>{actu.nom}</p>
      <p className='dateActuDetail'>{ConvertDate(actu.date)}</p>
      <div className='containerActuDetail'>
        <div className='imgActuDetail col-lg-5 col-sm-12'>
          <img src={actu.image.secure_url} alt='actualité' />
        </div>
        <div className=' textActuDetail col-lg-7 col-sm-12'>
          <p className='minDescriptionActuDetail preline'>
            {actu.minDescription}
          </p>
          <p className='descriptionActuDetail preline'>{actu.description}</p>
          {actu.lien && (
            <div className='linkActuDetail'>
              <a
                href={actu.lien}
                target='_blank'
                rel='noreferrer'
                alt="site pour en savoir plus sur l'actualité">
                En savoir +
              </a>
            </div>
          )}
        </div>
      </div>
      <div
        className={`btnActuDetail ${
          tabId.indexOf(params.id) === tabId.length - 1
            ? "end"
            : params.id === tabId[0]
            ? "end"
            : "space"
        }`}>
        {previousActu()}
        {nextActu()}
      </div>
    </Container>
  ) : (
    <p>Aucune actualité</p>
  );
};

export default ActualityDetails;
