import { Link } from "react-router-dom";
import "./cardcustom.css";
const CardCustom = ({ spectacle, url }) => {
  return (
    <Link to={url}>
      <div
        className='card text-white card-has-bg click-col'
        style={{
          backgroundImage: `url(${spectacle.affiche.secure_url})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}>
        <div className='card-img-overlay '>
          <div className='card-body'>
            <h3 className='card-title'>{spectacle.nom}</h3>
            <small>
              <p>{spectacle.minDescription}</p>
            </small>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CardCustom;
