import { TailSpin } from "react-loader-spinner";
import "./isLoading.css";

const IsLoadingContact = () => {
  return (
    <TailSpin height='20' width='20' color={"black"} ariaLabel='loading' />
  );
};

export default IsLoadingContact;
