import img from "../../assets/img/img.webp";
import "./toggletext.css";
const ToggleText = () => {
  return (
    <div className='containerPresentation container'>
      <div className='imgPresentation '>
        <img src={img} alt='acteur de la troupe' />
      </div>
      <div className='presentation '>
        <h2 className='titlePresentation'>
          UNE AVENTURE <br /> MUSICALE
          <span> DEPUIS 2003</span>
        </h2>
        <div className='txtPresentation'>
          <p>
            Notre association a pour but : la création et la diffusion de
            spectacles musicaux. Elle se compose de professionnels du spectacle,
            metteurs en scène, chorégraphes, artistes qui sont à la fois
            comédiens, chanteurs, danseurs et musiciens, techniciens, adhérents,
            bénévoles. La Boitazik a pour vocation la création de comédies
            musicales. Depuis 2006, ses spectacles ont été représentés dans de
            nombreuses salles parisiennes, de provinces ainsi qu’à l’étranger.
          </p>
          <br />
          <h3>Les spectacles :</h3>
          <p>
            - Amnésia (Lauréate du Prix découverte aux Musicals de Béziers,
            décerné à l’unanimité par un jury présidé par Claude-Michel
            Schönberg) - Les Contes de la Marne - Les Contes de La Rochelle - La
            Fée aux larmes roses - L’Etoile de Poubo - On a volé le LA... -
            BigMother (en cours de création)
          </p>
        </div>
      </div>
    </div>
  );
};

export default ToggleText;
