import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faInfoCircle,
  faList,
  faMapMarkerAlt,
  faPhoneAlt,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer/Footer";
import NavBar from "./components/NavBar/NavBar";
import Actuality from "./containers/Actuality/Actuality";
import ActualityDetails from "./containers/ActualityDetails/ActualityDetails";
import Agenda from "./containers/Agenda/Agenda";
import AgendaDetails from "./containers/AgendaDetails/AgendaDetails";
import Home from "./containers/Home/Home";
import MentionsLegales from "./containers/MentionsLegales/MentionsLegales";
import NotFound from "./containers/NotFound/NotFound";
import SpectacleDetails from "./containers/SpectacleDetails/SpectacleDetails";
import Spectacles from "./containers/Spectacles/Spectacles";

library.add(
  faBars,
  faPlayCircle,
  faList,
  faInfoCircle,
  faMapMarkerAlt,
  faPhoneAlt
);

function App() {
  return (
    <div className='App'>
      <NavBar />
      <Routes>
        <Route path='/' index element={<Home />} />
        <Route path='mentionsLegales' element={<MentionsLegales />} />
        <Route path='actualites' element={<Actuality />} />
        <Route path='actualites/detail/:id' element={<ActualityDetails />} />
        <Route path='spectacles' element={<Spectacles />} />
        <Route path='spectacles/detail/:id' element={<SpectacleDetails />} />
        <Route path='agenda' element={<Agenda />} />
        <Route path='agenda/detail/:id' element={<AgendaDetails />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
