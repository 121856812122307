import { useContext } from "react";
import ItemAgenda from "../../components/ItemAgenda/ItemAgenda";
import Titles from "../../components/Titles/Titles";
import { SpectacleContext } from "../../Context/SpectacleContext";
const Agenda = () => {
  const { allEvents } = useContext(SpectacleContext);

  return (
    <div className='container containerPadding100'>
      <Titles title={"AGENDA"} color={"white"} margin='0 0 60px 0' />
      {allEvents.map((event, index) => {
        return <ItemAgenda key={index} event={event} />;
      })}
    </div>
  );
};

export default Agenda;
